<template>
  <div>
    <associatedOption
      :fieldAttributes="{
        name: fieldAttributes.name,
        options: operators,
        ...fieldAttributes,
      }"
      type="select"
      v-on="$listeners"
      :value="value"
      :disabled="checkReadOnly"
      :templateContent="result"
    ></associatedOption>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import { arrayFindInArray } from "@/js/helper.js";
import { getOperators } from "@/js/testStepsHelpers/commonTestFunctions.js";
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  components: { associatedOption },
  props: ["result", "fieldAttributes", "checkReadOnly", "value"],
  computed: {
    operators() {
      let operators = options.correctOperators;
      let attrID = this.result.valueLine.attribute;
      let arraySource = this.result.fields.attribute.associatedOption;
      let valueArray = arrayFindInArray(attrID, arraySource);

      let type = valueArray[2];

      return getOperators(type, operators);
    },
  },
};
</script>